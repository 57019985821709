<template>
<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div v-if="true" class="flex justify-center max-w-md space-y-8 w-full">
    <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Magic } from 'magic-sdk';
import { store } from '../store.js';

const magic = new Magic('pk_live_7F06D4CDC50CBA64');
// magic.preload;




export default {
  name: 'Login',
  props: {
      msg: String
  },
  created() {
    this.handleLogout()
  },
  methods: {
    countryCodeEmoji(cc) {
      if (!cc) return ''
      if (!cc.geo) return ''
      if (!cc.geo.countryCode) return ''
      cc = cc.geo.countryCode
      const CC_REGEX = /^[a-z]{2}$/i;
      const OFFSET = 127397;
      if (!CC_REGEX.test(cc)) {
        const type = typeof cc;
        throw new TypeError(
          `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
            type === 'string' ? cc : type
          }' instead.`,
        );
      }
      const chars = [...cc.toUpperCase()].map(c => c.charCodeAt() + OFFSET);
      return String.fromCodePoint(...chars);
    },
    async handleLogout() {
      console.log('handleLogout1...')
      await magic.user.logout();
      console.log('handleLogout2...')
      this.storeState.isLoggedIn = false
      this.$router.push({ name: 'Landing'})
      // this.render();
    },
    async handleLogin(e) {
      // e.preventDefault();
      // const email = this.email
      if (this.email.length > 0) {
        window.posthog.capture('login-attempt', {email: this.email});
        /* One-liner login 🤯 */
        await magic.auth.loginWithMagicLink({ email: this.email });
        this.render();
      }
    },
    // handleLogin(e) {
    //   // e.preventDefault();
    //   // const email = this.email
    //   if (this.email) {
    //     /* One-liner login 🤯 */
    //     magic.auth.loginWithMagicLink({ email: this.email });
    //     this.render();
    //   }
    // },
    /* 3. Implement Render Function */
    async render() {
      this.storeState.isLoggedIn = await magic.user.isLoggedIn();
      /* Show login form if user is not logged in */
      if (this.storeState.isLoggedIn) {
        /* Get user metadata including email */
        const userMetadata = await magic.user.getMetadata();
        this.storeState.user = userMetadata
        console.log('logged in...', userMetadata.email)
        const idToken = await magic.user.getIdToken();
        this.loginUser(userMetadata, idToken)
        if (this.$route.query.goto) {
          this.$router.push({ name: this.$route.query.goto, params: { user: userMetadata }})
        } else {
          this.$router.push({ name: 'Landing', params: { user: userMetadata }})
        }
        this.checkedIfLoggedIn = true
      } else {
        this.checkedIfLoggedIn = true
      }
    },
    formatRank(rank) {
      if (rank < 60) {
        return 'F'
      } else if (rank < 70) {
        return 'D'
      } else if (rank < 80) {
        return 'C'
      } else if (rank < 90) {
        return 'B'
      } else if (rank < 90) {
        return 'A'
      }
      return 'A+'
    },
    formatCash(cash) {
      if (cash >= 1000000) return `$${(cash/1000000).toFixed(1)}M`
      return `$${(cash/1000).toFixed(1)}K`
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paperapi.onrender.com/${endpoint}`
    },
    generateDemoTable() {
      // new Grid({
      //   sort: true,
      //   search: true,
      //   columns: ["Name", "Email", "Phone Number"],
      //   data: [
      //     ["John", "john@example.com", "(353) 01 222 3333"],
      //     ["Mark", "mark@gmail.com", "(01) 22 888 4444"],
      //     ["Eoin", "eoin@gmail.com", "0097 22 654 00033"],
      //     ["Sarah", "sarahcdd@gmail.com", "+322 876 1233"],
      //     ["Afshin", "afshin@mail.com", "(353) 22 87 8356"]
      //   ],
        // className: {
        //   td: 'px-6 py-4 whitespace-nowrap text-sm text-gray-900 divide-y divide-gray-200',
        //   th: 'px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
        //   table: 'min-w-full divide-gray-200',
        //   tbody: 'bg-white '
        // }
      // }).render(document.getElementById("wrapper"));
    },
    filterFunders() {
      if (this.companyStage === 'bootstrapped') {
        this.filteredFunders = this.funderData.filter(row => row.min_annual_revenue <= 1000000)
      } else if (this.companyStage === 'growth') {
        this.filteredFunders = this.funderData.filter(row => row.min_annual_revenue <= 10000000)
      } else {
        this.filteredFunders = this.funderData
      }
    },
    loginUser(userMetadata, idToken) {
      this.gotFunders = false
      userMetadata.idToken = idToken
      const path = this.getApiUrl('login')
      axios.post(path, userMetadata)
        .then((res) => {
          console.log('did login: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getFunders() {
      this.gotFunders = false
      const path = this.getApiUrl('get_funders')
      let d = {}
      axios.post(path, d)
        .then((res) => {
          console.log('got handle_app_submission: ', res.data)
          this.gotFunders = true
          // this.funderColumns = Object.keys(res.data.columns).filter(c => !['domain', 'data'].includes(c))
          this.funderData = res.data.data
          this.filteredFunders = this.funderData
          // new Grid({
          //   sort: true,
          //   search: true,
            // columns: ["Name", "Email", "Phone Number"],
            // data: res.data.data
            // className: {
            //   td: 'px-6 py-4 whitespace-nowrap text-sm text-gray-900 divide-y divide-gray-200',
            //   th: 'px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
            //   table: 'min-w-full divide-gray-200',
            //   tbody: 'bg-white '
            // }
          // }).render(document.getElementById("wrapper"));
        })
        .catch((error) => {
          console.error(error)
        })
    },
    saveFunder(row) {
      row
    },
    applyToFunder(row) {
      let url = `http://${row.domain}/?ref=trypaperio`
      window.open(url, '_blank');
    },
    submitApp() {
      if (!this.appReady) {
        let field = 'email'
        this.appError = `${field} is required.`
        return
      }
      this.submitted = true
      const path = this.getApiUrl('handle_app_submission')
      let d = {application: this.loan, sessionId: ''}
      axios.post(path, d)
        .then((res) => {
          console.log('got handle_app_submission: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    goHome() {
      this.$router.push({ name: 'Landing', params: { userId: 123 }})
    },
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
  },
  computed: {
    appReady() {
      if (this.loan.email.length > 3) return true
      return false
    }
  },
  watch: {
    'companyStage': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        checkedIfLoggedIn: false,
        email: '',
        gotFunders: true,
        companyStage: 'bootstrapped',
        funderColumns: ['name', 'paper_rank', 'max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        funderCashColumns: ['max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        // detailCols: ['']
        columnLabels: {
          name: 'Funder',
          min_loan_amount: 'Revenue'
        },
        funderData: [],
        filteredFunders: [],
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  }
}
/* eslint-enable no-unused-vars */

</script>

