<template>
  <div class="bg-gray-50 min-h-full h-screen">
    <!-- LOADING.... -->
    <div v-if="!storeState.checkedLogin" class="flex justify-center pt-48 space-y-8 w-full">
      <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <!-- LOADED. -->
    <div v-else>
      <!-- SUBMITTED / DONE -->
      <div v-if="submitted" class="md:grid md:grid-cols-12 md:gap-0 py-16 bg-gray-50">
        <div class="md:col-span-1"></div>
        <div class="md:col-span-10">
          <div class="flex justify-center w-full">
            <div>
              <a @click="goHome" class="cursor-pointer" aria-label="Home">
                <img class="h-8 w-auto sm:h-16" src="/paper-logo.png" alt="Logo">
              </a>
            </div>
          </div>
          <div class="mt-8 text-center text-gray-900">
            <h2 class="text-3xl font-extrabold">
              Application Received!
            </h2>
            <p class="mt-2 mx-auto text-gray-700">
              Here's what to expect next. Feel free to drop us a note 
              <a class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank" href="mailto:contact@trypaper.io">here</a>
              or in the chat widget in the bottom right of screen.
            </p>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="text-left mt-8 sm:grid sm:grid-cols-8">
              <div class="col-span-2"></div>
              <div class="col-span-8 mx-auto">
                <nav aria-label="Progress">
                  <ol class="overflow-hidden">
                    <li class="relative pb-10">
                      <!-- Complete Step -->
                      <div class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true"></div>
                      <a href="#" class="relative flex items-start group">
                        <span class="h-9 flex items-center">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <!-- Heroicon name: solid/check -->
                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase">Create account</span>
                          <span class="text-sm text-gray-500">Account and email verified.</span>
                        </span>
                      </a>
                    </li>
                    <li class="relative pb-10">
                      <!-- Complete Step -->
                      <div class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true"></div>
                      <a href="#" class="relative flex items-start group">
                        <span class="h-9 flex items-center">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <!-- Heroicon name: solid/check -->
                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase">Select capital providers</span>
                          <span class="text-sm text-gray-500">Select the types of services and the capital providers you're interested in working with.</span>
                        </span>
                      </a>
                    </li>
                    <li class="relative pb-10">
                      <!-- Complete Step -->
                      <div class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true"></div>
                      <a href="#" class="relative flex items-start group">
                        <span class="h-9 flex items-center">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <!-- Heroicon name: solid/check -->
                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase">Application submitted</span>
                          <span class="text-sm text-gray-500">Provide financials and other details about your company.</span>
                        </span>
                      </a>
                    </li>
                    <li class="relative pb-10">
                      <!-- Current Step -->
                      <div class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true"></div>
                      <a href="#" class="relative flex items-start group" aria-current="step">
                        <span class="h-9 flex items-center" aria-hidden="true">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                            <span class="h-3 w-3 animate-pulse bg-indigo-600 rounded-full"></span>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase text-indigo-600">Capital providers review</span>
                          <span class="text-sm text-gray-500">Your application is reviewed and capital providers will contact you with questions.</span>
                        </span>
                      </a>
                    </li>
                    <li class="relative pb-10">
                      <div class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true"></div>
                      <a href="#" class="relative flex items-start group">
                        <span class="h-9 flex items-center" aria-hidden="true">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase text-gray-500">Select a provider</span>
                          <span class="text-sm text-gray-500">Review your offers and select the one that fits your company best.</span>
                        </span>
                      </a>
                    </li>

                    <li class="relative">
                      <a href="#" class="relative flex items-start group">
                        <span class="h-9 flex items-center" aria-hidden="true">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase text-gray-500">Funded!</span>
                          <span class="text-sm text-gray-500">Capital is normally available within 72 hours from picking a providers.</span>
                        </span>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="col-span-2"></div>
            </div>
            <div class="md:grid md:grid-cols-3 md:gap-8 mt-8">
              <div>
                <a @click="goHome" class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank">Home</a>
              </div>
              <div>
                <a class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank" href="https://twitter.com/paperfinancial">Twitter</a>
              </div>
              <div>
                <a class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank" href="mailto:contact@trypaper.io">Email</a>
              </div>
            </div>
          </div>
        </div>
        <div class="md:col-span-1"></div>
      </div>
      <!-- END SUBMITTED / DONE -->
      <!-- HEADER -->
      <div v-if="!submitted" class="md:grid md:grid-cols-12 md:gap-0 py-16 bg-gray-50">
        <div class="md:col-span-1"></div>
        <div class="md:col-span-10">
          <div class="mt-10 sm:mt-0">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <!-- <h3 class="text-lg font-medium leading-6 text-gray-900">Logo</h3> -->
                  <!-- <p class="mt-1 text-sm leading-5 text-gray-600">
                    Use a permanent address where you can receive mail.
                  </p> -->
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="flex justify-center w-full">
                  <a @click="goHome" class="cursor-pointer" aria-label="Home">
                    <img class="h-8 w-auto sm:h-16" src="/paper-logo.png" alt="Logo">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="md:col-span-1"></div>
      </div>
      <!-- END HEADER -->
      <!-- APPLICATION -->
      <div v-if="!submitted" class="md:grid md:grid-cols-12 md:gap-0 pt-0 pb-16 bg-gray-50">
        <div class="md:col-span-1"></div>
        <div class="md:col-span-10">
          <div class="mt-10 sm:mt-0">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Basic Information</h3>
                  <!-- <p class="mt-1 text-sm leading-5 text-gray-600">
                    Use a permanent address where you can receive mail.
                  </p> -->
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div class="shadow overflow-hidden sm:rounded-md">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First name</label>
                          <input v-model="loan.firstName" id="first_name" class="block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full">
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>
                          <input v-model="loan.lastName" id="last_name" class="block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full">
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="company_name" class="block text-sm font-medium leading-5 text-gray-700">Company name</label>
                          <input autocomplete="off" v-model="loan.companyName" id="company_name" class="block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full">
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="country" class="block text-sm font-medium leading-5 text-gray-700">Country / Region</label>
                          <paper-select
                            placeholder="Country"
                            :options="countries"
                            label=""
                            @selectedd="(val) => {loan.country = val.value}"
                          >
                          </paper-select>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="email_address" class="block text-sm font-medium leading-5 text-gray-700">Email address</label>
                          <input v-model="loan.email" id="email_address" class="block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="hidden sm:block">
            <div class="py-5">
              <div class="border-t border-gray-200"></div>
            </div>
          </div>

          <div class="mt-10 sm:mt-0">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Loan & Financials</h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600">
                  </p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div class="shadow overflow-hidden sm:rounded-md">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="text-base font-medium text-gray-900">
                        <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">Loan Amount</label>
                        <paper-currency
                          v-model="loan.amount" 
                          :options="{ currency: 'USD', precision: 0 }"
                          class="w-20 block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full"
                        />
                      </div>
                      <fieldset class="mt-6">
                        <legend class="text-base leading-6 font-medium text-gray-900">Metric Calculation</legend>
                        <p class="text-sm leading-5 text-gray-500">Please select how you'd like your metrics calculated.</p>
                        <div class="mt-4">
                          <div class="flex items-center">
                            <input v-model="loan.metricCalcType" value="stripe" name="metric_calc_type" type="radio" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                            <div class="ml-3 text-sm">
                              <label for="stripe" class="font-medium text-gray-700">Stripe</label>
                              <p class="text-gray-500">We'll use your Stripe data to calculate MRR, churn, etc.</p>
                            </div>
                            
                          </div>
                          <div class="mt-4 flex items-center">
                            <input v-model="loan.metricCalcType" value="profitwell" name="metric_calc_type" type="radio" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                            <div class="ml-3 text-sm">
                              <label for="profitwell" class="font-medium text-gray-700">ProfitWell</label>
                              <p class="text-gray-500">We'll use the metrics calculated by 
                                <a class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank" href="https://www.profitwell.com/">ProfitWell.</a>
                              </p>
                            </div>
                          </div>
                          <div class="mt-4 flex items-center">
                            <input v-model="loan.metricCalcType" value="baremetrics" name="metric_calc_type" type="radio" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                            <div class="ml-3 text-sm">
                              <label for="baremetrics" class="font-medium text-gray-700">Baremetrics</label>
                              <p class="text-gray-500">We'll use the metrics calculated by 
                                <a class="underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out" target="_blank" href="https://www.baremetrics.com/">Baremetrics.</a>
                              </p>
                            </div>
                          </div>
                          <div class="mt-4 flex items-center">
                            <input v-model="loan.metricCalcType" value="manual" name="metric_calc_type" type="radio" class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                            <div class="ml-3 text-sm">
                              <label for="manual" class="font-medium text-gray-700">Input manually</label>
                              <p class="text-gray-500">You'll key in metrics which we'll later confirm.
                              </p>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div v-if="loan.metricCalcType==='manual'" class="mt-16">
                        <div 
                          v-for="(mrr, index) in loan.mrrs"
                          :key="index"
                          class="mt-8"
                        >
                          <div class="col-span-6 sm:col-span-3">
                            <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">{{mrr.label}}</label>
                            <paper-currency
                              v-model="mrr.value"
                              :options="{ currency: 'USD', precision: 0 }"
                              class="w-20 block border duration-150 ease-in-out form-input mt-1 p-2 rounded sm:leading-5 sm:text-sm transition w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="mt-12">
                  <button 
                    @click="submitApp" 
                    class="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out"
                    :class="{
                      'opacity-50' : !appReady,
                      'cursor-not-allowed	' : !appReady,
                      }"
                  >
                    Submit
                  </button>
                </div>
                <div v-if="!appReady && appError" class="text-red-700 mt-4 text-center">
                  {{appError}}
                </div>
                
                
              </div>
              
            </div>
            
          </div>
          
        </div>
        <div class="md:col-span-1"></div>
      </div>

    </div>
    
    <!-- END APPLICATION -->
  </div>
</template>

<script>
import axios from 'axios';
import { store } from '../store.js';
import PaperCurrency from '@/components/PaperCurrency.vue'
import PaperSelect from '@/components/PaperSelect.vue'

export default {
  name: 'Apply',
  props: {
      msg: String
  },
  components: {
    // PaperMenu,
    PaperCurrency,
    PaperSelect,
  },
  mounted() {
    
  },
  created() {
    this.emitter.on('user-login', () => this.onUserLogin());
  },
  methods: {
    onUserLogin() {
      console.log('onUserLogin...')
      this.checkLoginRedirect()
    },
    checkLoginRedirect() {
      if (this.storeState.checkedLogin && !this.storeState.isLoggedIn) {
        this.$router.push({ name: 'Login', query: { goto: 'Apply' }})
      }
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paperapi.onrender.com/${endpoint}`
    },
    submitApp() {
      if (!this.appReady) {
        let field = 'email'
        this.appError = `${field} is required.`
        return
      }
      this.submitted = true
      const path = this.getApiUrl('/handle_app_submission')
      // let d = {application: this.loan, sessionId: ''}
      let d = {user: this.storeState.user, application: this.loan}
      axios.post(path, d)
        .then((res) => {
          console.log('got handle_app_submission: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    goHome() {
      this.$router.push({ name: 'Landing', params: { userId: 123 }})
    },
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
  },
  computed: {
    appReady() {
      if (this.loan.email.length > 3) return true
      return false
    }
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        countries: [
          {name: 'United States', value: 'United States'},
          {name: 'Canada States', value: 'Canada'},
          {name: 'United Kingdom', value: 'United Kingdom'},
          {name: 'Australlia', value: 'Australlia'},
          {name: 'Other', value: 'Other'},
        ],
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  }
}
</script>

