<template>
<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<div class="h-screen flex overflow-hidden bg-gray-100">
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div v-if="openSideBar" class="md:hidden">
    <div class="fixed inset-0 flex z-40">
      <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button @click="openSideBar=false" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Close sidebar</span>
            <!-- Heroicon name: x -->
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-shrink-0 flex items-center px-4">
          <img class="h-8 w-auto" src="/paper-logo-text.png" alt="Paper">
        </div>
        <div class="mt-5 flex-1 h-0 overflow-y-auto">
          <nav class="px-2 space-y-1">
            <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <a 
              @click="section='funders'" 
              class="cursor-pointer text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
              :class="{'bg-gray-100': section==='funders'}"
            >
              <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
              <!-- Heroicon name: home -->
              <svg class="text-gray-500 mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Funders
            </a>

            

            <a @click="section='applications'" class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
              <!-- Heroicon name: users -->
              <svg class="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
              </svg>
              Applications
            </a>

            <a @click="section='team'" class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
              <!-- Heroicon name: users -->
              <svg class="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
              </svg>
              Team
            </a>
          </nav>
        </div>
      </div>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow border-r border-gray-200 pt-2 pb-1 bg-white overflow-y-auto">
        <div class="flex items-center flex-shrink-0">
          <img class="h-12 w-auto" src="/paper-logo-text.png" alt="Paper">
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-2 bg-white space-y-1">
            <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <a 
              @click="section='funders'" 
              class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              :class="{'bg-gray-100': section==='funders', 'text-gray-900': section==='funders'}"
            >
            
              <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
              <!-- Heroicon name: home -->
              <svg 
                class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" 
                :class="{'text-gray-900': section==='funders'}"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Funders
            </a>

            <a 
              @click="section='applications'" 
              class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              :class="{'bg-gray-100': section==='applications', 'text-gray-900': section==='applications'}"
            >
              <!-- Heroicon name: users -->
              <svg 
                class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" 
                :class="{'text-gray-900': section==='applications'}"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
              </svg>
              Applications
            </a>
            <a 
              @click="section='team'" 
              class="cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              :class="{'bg-gray-100': section==='team', 'text-gray-900': section==='team'}"
            >
              <!-- Heroicon name: users -->
              <svg 
                class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" 
                :class="{'text-gray-900': section==='team'}"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              Team
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col w-0 flex-1 overflow-hidden">
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button @click="openSideBar=true" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: menu-alt-2 -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>
      <div class="flex-1 px-4 flex justify-between">
        <div class="flex-1 flex">
          <form class="w-full flex md:ml-0" action="#" method="GET">
            <label for="search_field" class="sr-only">Search</label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
              <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <!-- Heroicon name: search -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input v-model="searchTerm" id="search_field" class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search" type="search" name="search">
            </div>
          </form>
        </div>
        <div class="ml-4 flex items-center md:ml-6">
          <!-- <button class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button> -->

          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button @click="showUserMenu=!showUserMenu" class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <!-- <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
              </button>
            </div>
            <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div @click="showUserMenu=false" v-if="showUserMenu" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
              <a v-if="!storeState.isLoggedIn" @click="login" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign in</a>
              <a v-else @click="store.handleLogout" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
              <div v-if="storeState.user.email" class="cursor-pointer block px-4 py-2 text-sm text-gray-900 border-t">
                {{storeState.user.email}}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <main v-if="section!='funders'" class="flex-1 relative overflow-y-auto focus:outline-none" tabindex="0">
      <div class="py-6">
        <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div> -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <!-- Replace with your content -->
          <div>
            <div class="md:grid md:grid-cols-12 md:gap-0 py-8">
              <div class="md:col-span-1"></div>
              <div class="md:col-span-10">
                <div v-if="storeState.isLoggedIn" class="text-xl">
                    Coming soon
                </div>
                <div v-else class="">
                  <a @click="login" class="text-xl font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out">
                    Sign in to view
                  </a>
                </div>
              </div>
              <div class="md:col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <main v-if="section==='funders'" class="flex-1 relative overflow-y-auto focus:outline-none" tabindex="0">
      <div class="py-6">
        <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div> -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <!-- Replace with your content -->
          <div>
            <div class="md:grid md:grid-cols-12 md:gap-0 py-8">
              <div class="md:col-span-1"></div>
              <div class="md:col-span-10">
                <div class="md:grid md:grid-cols-12 md:gap-0">
                  <div
                    @click="companyStage='bootstrapped'" 
                    class="md:col-span-4 p-4 hover:shadow cursor-pointer rounded" 
                    :class="{
                        'border-2': companyStage === 'bootstrapped',
                        'border-indigo-600': companyStage === 'bootstrapped',
                      }"
                  >
                    <div class="flex content-center justify-center">
                      <div>
                        <svg class="w-12 h-12 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                        </svg>
                      </div>
                    </div>
                      <div class="text-center text-lg text-gray-900">
                        Bootstrapped / Early Stage
                      </div>
                      <div class="text-center text-base text-gray-500">
                        &lt; $1M Annual Revenue
                      </div>
                  </div>
                  <div
                    @click="companyStage='growth'" 
                    class="md:col-span-4 p-4 hover:shadow cursor-pointer rounded" 
                    :class="{
                        'border-2': companyStage === 'growth',
                        'border-indigo-600': companyStage === 'growth',
                      }"
                  >
                    <div class="flex content-center justify-center">
                      <div>
                        <svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                        </svg>
                      </div>
                    </div>
                      <div class="text-center text-lg text-gray-900">
                        Growth
                      </div>
                      <div class="text-center text-base text-gray-500">
                        $1M to $10M Annual Revenue
                      </div>
                  </div>
                  <div
                    @click="companyStage='scale'" 
                    class="md:col-span-4 p-4 hover:shadow cursor-pointer rounded" 
                    :class="{
                        'border-2': companyStage === 'scale',
                        'border-indigo-600': companyStage === 'scale',
                      }"
                  >
                    <div class="flex content-center justify-center">
                      <div>
                        <svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                        </svg>
                      </div>
                    </div>
                      <div class="text-center text-lg text-gray-900">
                        Scale
                      </div>
                      <div class="text-center text-base text-gray-500">
                        $10M+ Annual Revenue
                      </div>
                  </div>
                </div>
              </div>
              <div class="md:col-span-1"></div>
            </div>
            <div class="md:grid md:grid-cols-12 md:gap-0">
              <div class="md:col-span-1"></div>
              <div class="md:col-span-10 pt-8">
                <!-- <div v-if="gotFunders" id="wrapper" class=""></div> -->
                <div>
                  <div id="funderWrapper" class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg" style="height:500px;">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <!-- <th 
                            v-for="(col, index) in funderColumns"
                            :key="index"
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
                            {{col}}
                          </th> -->
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Funder
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Rating
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Country
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Loan Amount
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Revenue 
                              <div class="tooltip bg-gray-200 h-4 inline-block rounded-full text-center w-4">?
                                <span class='tooltip-text bg-gray-900 text-white normal-case p-3 -mt-8 -ml-28 rounded'>
                                  Minimum Annual Revenue
                                </span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody 
                        v-for="(row, index) in filteredFunders"
                        :key="index"
                        class="bg-white divide-y divide-gray-200"
                        @click="setRowOpen(index, row, $event)"
                      >
                        <tr
                          class="hover:bg-gray-100 hover:shadow-sm cursor-pointer"
                        >
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              <img v-if="row.data && row.data.logo" class="rounded-full w-4 inline-block mr-1" :src="row.data ? row.data.logo : ''">
                              <img v-else class="rounded-full h-4 inline-block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/6/68/Solid_black.png">
                              <a :href="`http://${row.domain}/?ref=trypaperio`" target="_blank" class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out">
                                {{row.name}}
                              </a>
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              {{formatRank(row.paper_rank)}}
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div class="text-center">{{countryCodeEmoji(row.data)}}</div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              {{formatCash(row.min_loan_amount)}} - {{formatCash(row.max_loan_amount)}}
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              {{formatCash(row.min_annual_revenue)}}
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="hover:bg-gray-100 hover:shadow-sm"
                          @click="row.open=true"
                          v-if="row.open"
                          data-onclick="open"
                        >
                          <td colspan="5" data-onclick="open" class="">
                            <div data-onclick="open" class="px-4 py-5 sm:px-6">
                              <dl data-onclick="open" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Location 
                                    <!-- {{countryCodeEmoji(row.data)}} -->
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{getGeoSummary(row)}}
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">
                                    Funding
                                    <div data-onclick="open" class="tooltip bg-gray-200 h-5 w-5 inline-block rounded-full text-center">?
                                      <span class='tooltip-text bg-gray-900 text-white normal-case p-3 -mt-8 -ml-28 rounded'>
                                        How much this company has raised (debt + equity)
                                      </span>
                                    </div>
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.crunchbase && row.data.crunchbase.handle">
                                      <a data-onclick="open" class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="`https://www.crunchbase.com/${row.data.crunchbase.handle}`">
                                        {{formatCash(row.data.metrics.raised)}}
                                      </a>
                                    </span>
                                    <span data-onclick="open" v-else>
                                      {{formatCash(row.data.metrics.raised)}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">
                                    Email
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.site.emailAddresses && row.data.site.emailAddresses.length > 0">
                                      <a class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="row.data.site.emailAddresses">{{row.data.site.emailAddresses[0]}}</a>
                                      
                                    </span>
                                    <span data-onclick="open" v-else>No email</span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Employees
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.linkedin && row.data.linkedin.handle">
                                      <a data-onclick="open" class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="`https://www.linkedin.com/${row.data.linkedin.handle}`">
                                        {{row.data.metrics.employees ? row.data.metrics.employees : '&lt; 5'}}
                                      </a>
                                    </span>
                                    <span data-onclick="open" v-else>{{row.data.metrics.employees ? row.data.metrics.employees : '&lt; 5'}}</span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Type
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{row.loan_type}}
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Payment
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span v-if="row.loan_type === 'Factoring'">
                                      No repayment
                                    </span>
                                    <span v-else>
                                      {{row.payment_details}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Warrants
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span>
                                      {{row.warrants ? row.warrants : 'Unknown'}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Days to Close
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span>
                                      {{row.days_to_close ? row.days_to_close : 'Unknown'}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-2">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    About
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{row.data.description}}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            <div data-onclick="open" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 pb-8 px-6">
                              <div data-onclick="open" class="sm:col-span-2"></div>
                              <div v-if="storeState.userData.savedFunders.includes(row.domain)" data-onclick="open" class="sm:col-span-1">
                                <button 
                                  data-onclick="open"
                                  @click="removeFunder(row)" 
                                  class="w-full py-2 px-4 border text-sm font-medium rounded-md bg-white text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                >
                                  Remove
                                </button>
                              </div>
                              <div v-else data-onclick="open" class="sm:col-span-1">
                                <button 
                                  data-onclick="open"
                                  @click="saveFunder(row)" 
                                  class="w-full py-2 px-4 border text-sm font-medium rounded-md bg-white text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                >
                                  Save
                                </button>
                              </div>
                              <div data-onclick="open" class="sm:col-span-1">
                                <button 
                                  data-onclick="open"
                                  @click="applyToFunder(row)" 
                                  class="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out"
                                >
                                  Apply
                                </button>
                              </div>
                              
                            </div>
                            <!-- {{row.data}} -->
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div @click="login" v-if="!storeState.user.email" class="cursor-pointer bg-black hover:bg-gray-700 text-white block mt-4 px-4 py-4 text-base rounded">
                    Sign in to view full list of 100+ sources (takes &lt; 30 seconds)
                  </div>
                </div>
              </div>
              <div class="md:col-span-1"></div>
            </div>
            
          </div>
          <!-- /End replace -->
        </div>
      </div>
    </main>
  </div>
</div>
  
</template>

<style>
  .gridjs-tbody {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5;
    --border-opacity: 1;
    border-collapse: collapse;
    box-sizing: border-box;
    border-width: 1px !important;
    border-style: solid;
    --bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    --divide-opacity: 1;
    border-color: rgba(237, 242, 247, var(--divide-opacity));
  }
</style>

<script>
import axios from 'axios';
import { store } from '../store.js';


export default {
  name: 'Funders',
  props: {
      msg: String
  },
  mounted() {
    this.generateDemoTable()
    this.getFunders()
    document.getElementById("funderWrapper").addEventListener("scroll",function(){
      var translate = "translate(0,"+this.scrollTop+"px)";
      this.querySelector("thead").style.transform = translate;
    });
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Funders' }})
    },
    getGeoSummary(row) {
      if (row.data.geo.city && row.data.geo.countryCode === 'US') return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.city && row.data.geo.state) return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.state) return `${row.data.geo.state}`
      return row.data.geo
      // return row.data.geo.country
      
    },
    countryCodeEmoji(cc) {
      if (!cc) return ''
      if (!cc.geo) return ''
      if (!cc.geo.countryCode) return ''
      cc = cc.geo.countryCode
      const CC_REGEX = /^[a-z]{2}$/i;
      const OFFSET = 127397;
      if (!CC_REGEX.test(cc)) {
        const type = typeof cc;
        throw new TypeError(
          `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
            type === 'string' ? cc : type
          }' instead.`,
        );
      }
      const chars = [...cc.toUpperCase()].map(c => c.charCodeAt() + OFFSET);
      return String.fromCodePoint(...chars);
    },
    formatRank(rank) {
      if (rank < 60) {
        return 'F'
      } else if (rank < 70) {
        return 'D'
      } else if (rank < 80) {
        return 'C'
      } else if (rank < 90) {
        return 'B'
      } else if (rank < 90) {
        return 'A'
      }
      return 'A+'
    },
    setRowOpen(index, row, event) {
      if (event.target.dataset.onclick) {
        return
      }
      row.open = !row.open
      // Vue.set(this.funderData, index, row)
      this.$forceUpdate()
    },
    formatCash(cash) {
      if (cash >= 1000000) return `$${(cash/1000000).toFixed(0)}M`
      return `$${(cash/1000).toFixed(0)}K`
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paperapi.onrender.com/${endpoint}`
    },
    generateDemoTable() {
      // new Grid({
      //   sort: true,
      //   search: true,
      //   columns: ["Name", "Email", "Phone Number"],
      //   data: [
      //     ["John", "john@example.com", "(353) 01 222 3333"],
      //     ["Mark", "mark@gmail.com", "(01) 22 888 4444"],
      //     ["Eoin", "eoin@gmail.com", "0097 22 654 00033"],
      //     ["Sarah", "sarahcdd@gmail.com", "+322 876 1233"],
      //     ["Afshin", "afshin@mail.com", "(353) 22 87 8356"]
      //   ],
        // className: {
        //   td: 'px-6 py-4 whitespace-nowrap text-sm text-gray-900 divide-y divide-gray-200',
        //   th: 'px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
        //   table: 'min-w-full divide-gray-200',
        //   tbody: 'bg-white '
        // }
      // }).render(document.getElementById("wrapper"));
    },
    filterFunders() {
      if (this.companyStage === 'bootstrapped') {
        this.filteredFunders = this.funderData.filter(row => row.min_annual_revenue <= 1000000)
      } else if (this.companyStage === 'growth') {
        this.filteredFunders = this.funderData.filter(row => row.min_annual_revenue <= 10000000)
      } else {
        this.filteredFunders = this.funderData
      }
      if (this.searchTerm.length > 0) {
        this.filteredFunders = this.filteredFunders.filter(row => row.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      }
      
    },
    getFunders() {
      this.gotFunders = false
      const path = this.getApiUrl('get_funders')
      let d = {}
      axios.post(path, d)
        .then((res) => {
          console.log('got handle_app_submission: ', res.data)
          this.gotFunders = true
          // this.funderColumns = Object.keys(res.data.columns).filter(c => !['domain', 'data'].includes(c))
          this.funderData = res.data.data
          if (this.storeState.isLoggedIn) {
            this.filteredFunders = this.funderData
          } else {
            this.filteredFunders = this.funderData.slice(0, 10)
            // this.filteredFunders = sample(this.funderData, 5);
          }
          
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updateUserData() {
      const path = this.getApiUrl('update_user_data')
      let d = {user: this.storeState.user, userData: this.storeState.userData}
      axios.post(path, d)
        .then((res) => {
          console.log('got update_user_data: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    removeFunder(row) {
      this.storeState.userData.savedFunders.pop(row.domain)  
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Funders' }})
      } else {
        // save funder to users db
        this.updateUserData()
      }
    },
    saveFunder(row) {
      if (this.storeState.userData.savedFunders.includes(row.domain)) {
        return
      }
      this.storeState.userData.savedFunders.push(row.domain)  
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Funders' }})
      } else {
        this.updateUserData()
      }
    },
    applyToFunder(row) {
      let url = `http://${row.domain}/?ref=trypaperio`
      window.open(url, '_blank');
    },
    submitApp() {
      if (!this.appReady) {
        let field = 'email'
        this.appError = `${field} is required.`
        return
      }
      this.submitted = true
      const path = this.getApiUrl('handle_app_submission')
      let d = {application: this.loan, sessionId: ''}
      axios.post(path, d)
        .then((res) => {
          console.log('got handle_app_submission: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    goHome() {
      this.$router.push({ name: 'Landing', params: { userId: 123 }})
    },
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
  },
  computed: {
    appReady() {
      if (this.loan.email.length > 3) return true
      return false
    }
  },
  watch: {
    'companyStage': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
    'searchTerm': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        openSideBar: false,
        searchTerm: '',
        section: 'funders',
        showUserMenu: false,
        gotFunders: true,
        companyStage: 'scale',
        funderColumns: ['name', 'paper_rank', 'max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        funderCashColumns: ['max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        // detailCols: ['']
        columnLabels: {
          name: 'Funder',
          min_loan_amount: 'Revenue'
        },
        funderData: [],
        filteredFunders: [],
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  }
}
</script>

