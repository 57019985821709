<template>
  <div class="">
    <!-- HERO -->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-gray-50 overflow-hidden">
      <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div class="relative h-full max-w-7xl mx-auto">
          <svg class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
          </svg>
        </div>
      </div>
      <!-- {{this.storeState.user}} {{this.storeState.isLoggedIn}} -->
      <!-- {{storeState}} -->

      <div class="relative pt-6 pb-16 sm:pb-24">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <nav class="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a @click="onSearchBlur" class="cursor-pointer">
                  <span class="sr-only">Paper</span>
                  <!-- <img class="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt=""> -->
                  <img class="h-8 w-auto sm:h-10" src="/paper-logo.png" alt="Logo">
                </a>
              </div>
            </div>
            <!-- <div class="hidden md:flex md:space-x-10">
              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Product</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Features</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Marketplace</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Company</a>
            </div> -->
            <div class="md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span class="inline-flex rounded-md shadow">
                <a v-if="!storeState.isLoggedIn" @click="login" class="inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  Sign up
                </a>
                <a v-else @click="logout" class="inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  Log out
                </a>
              </span>
            </div>
          </nav>
        </div>

        <!--
          Mobile menu, show/hide based on menu open state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        -->


        <main v-if="!searchFocused" class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div class="text-center">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span id="typed" class="inline"></span>
              <span class="inline text-indigo-600">to grow your SaaS</span>
            </h1>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              You're a SaaS founder. You have your hands full with product, growth, and support. 
              You don't have time to fill out paperwork or hunt down the best financing options. 
              We've done it all for you.
            </p>
            <div class="sm:text-center lg:text-left mt-8 lg:grid lg:grid-cols-8">
              <div class="col-span-1"></div>
              <div class="border rounded col-span-6 flex-1 flex">
                <form class="w-full flex md:ml-0" action="#" method="GET">
                  <label for="search_field" class="sr-only">Search</label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600 bg-gray-50">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                      <!-- Heroicon name: solid/search -->
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input 
                      autocomplete="off" 
                      id="search_field" 
                      class="border rounded block text-xl w-full border-transparent py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent" 
                      placeholder="Search" 
                      type="search" 
                      name="search"
                      @focus="onSearchFocus"
                      v-model="searchTerm"
                    >
                  </div>
                </form>
              </div>
              <div class="col-span-1"></div>
            </div>
            <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div class="rounded-md shadow">
                <a @click="onSearchFocus" class="md:w-48 w-auto cursor-pointer flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Search
                </a>
              </div>
              <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a target="_blank" href="https://drift.me/mike385/meeting" class="md:w-48 w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                  Book a call
                </a>
              </div>
            </div>
          </div>
        </main>
        <main v-else class="transition duration-1000 ease-in-out mt-4 mx-auto max-w-7xl px-4">
          <div class="sm:text-center lg:text-left mt-8 lg:grid lg:grid-cols-12">
            <div class="col-span-1"></div>
            <div class="col-span-10 text-center">
              <h1 class="text-4xl tracking-tight leading-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <!-- <span id="typed" class="inline">Apply to dozens of capital providers</span> -->
                <span class="inline">Get offers from 100+ lenders </span>
                <span class="inline text-indigo-600">in one click.</span>
              </h1>
            </div>
            <div class="col-span-1"></div>
          </div>
          <div class="sm:text-center lg:text-left mt-8 lg:grid lg:grid-cols-12">
            <div class="col-span-1"></div>
            <div class="border rounded col-span-10 flex-1 flex">
              
              <form class="w-full flex md:ml-0" action="#" method="GET">
                <label for="search_field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600 bg-gray-50">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                    <!-- Heroicon name: solid/search -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input 
                    ref="actualSearch"
                    v-model="searchTerm"
                    autocomplete="off" 
                    id="search_field" 
                    class="block text-xl w-full border-transparent py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent" 
                    placeholder="Search" 
                    type="search" 
                    name="search"
                    @focus="onSearchFocus"
                  >
                </div>
              </form>
            </div>
            <div class="col-span-1"></div>
          </div>
          <!-- SEARCH RESULTS -->
          <!-- rev: {{revenue}} -->
          <div class="my-8">
            <div class="md:grid md:grid-cols-12 md:gap-0">
              <div class="md:col-span-1"></div>
              <div class="md:col-span-10">
                <div class="md:grid md:grid-cols-12 md:gap-8">
                  <div class="md:col-span-4">
                    <div>
                      <paper-select
                        placeholder="some thing"
                        :options="productOptions"
                        label="Product"
                        @selectedd="(val) => {product = val}"
                      >
                      </paper-select>
                    </div>
                  </div>
                  <div class="md:col-span-4">
                    <div>
                      <paper-select
                        placeholder="some thing"
                        :options="revenueOptions"
                        label="Annual Revenue (ARR)"
                        @selectedd="(val) => {revenue = val}"
                      >
                      </paper-select>
                    </div>
                  </div>
                  <!-- <div class="md:col-span-4">
                    <div>
                      <paper-select
                        placeholder="some thing"
                        :options="locationOptions"
                        label="Location"
                        @selectedd="(val) => {location = val}"
                      >
                      </paper-select>
                    </div>
                  </div> -->
                  <div class="md:col-span-4">
                    <div>
                      <paper-select
                        placeholder="some thing"
                        :options="ratingOptions"
                        label="Rating"
                        @selectedd="(val) => {rating = val}"
                      >
                      </paper-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="md:col-span-1"></div>
            </div>
          </div>
          <div>
            <div class="md:grid md:grid-cols-12 md:gap-0">
              <div class="md:col-span-1"></div>
              <div class="md:col-span-10 pt-8">
                <!-- <div v-if="gotFunders" id="wrapper" class=""></div> -->
                <div>
                  <div id="funderWrapper" class="bg-white shadow overflow-auto border-b border-gray-200 sm:rounded-lg" style="height:500px;">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr
                          class="hidden w-screen sm:w-full sm:table-row"
                        >
                          <!-- <th 
                            v-for="(col, index) in funderColumns"
                            :key="index"
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
                            {{col}}
                          </th> -->
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            <div>
                              <PaperToggle
                                  @onselectedd="(val) => {selectAll = val}"
                                  :selectedd="selectAll"
                                  @click="selectAllNow"
                                  class=""
                                >
                              </PaperToggle>
                            </div>
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Funder
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Rating
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Country
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Loan Amount
                          </th>
                          <th 
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            Revenue 
                              <div class="tooltip bg-gray-200 h-4 inline-block rounded-full text-center w-4">?
                                <span class='tooltip-text bg-gray-900 text-white normal-case p-3 -mt-8 -ml-28 rounded'>
                                  Minimum Annual Revenue
                                </span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody align="center" v-if="!gotFunders || !storeState.gotUserData">
                        <tr class="w-full">
                          <td class="pt-16" colspan="12">
                            <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </td>
                        </tr>
                      </tbody>
                      <tbody align="center" v-else-if="filteredFunders.length === 0 && !storeState.user.email">
                        <tr class="w-full">
                          <td class="pt-16" colspan="12">
                            <div @click="login" class="bg-black block cursor-pointer font-bold hover:bg-gray-700 mt-4 mx-32 px-4 py-4 rounded text-lg text-white">
                              Sign in to view full list of 100+ services
                               <!-- (takes &lt; 30 seconds) -->
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody align="center" v-else-if="filteredFunders.length === 0">
                        <tr class="w-full">
                          <td class="pt-16" colspan="12">
                            <div @click="clearFilters" class="bg-black block cursor-pointer font-bold hover:bg-gray-700 mt-4 mx-32 px-4 py-4 rounded text-lg text-white">
                              No results, clear filters?
                               <!-- (takes &lt; 30 seconds) -->
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody 
                        v-else
                        v-for="(row, index) in filteredFunders"
                        :key="index"
                        class="bg-white divide-y divide-gray-200"
                      >
                        <tr
                          class="hover:bg-gray-100 hover:shadow-sm cursor-pointer flex sm:hidden"
                          @click="setRowOpen(index, row, $event)"
                        >
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              <img v-if="row.data && row.data.logo" class="rounded-full w-4 inline-block mr-1" :src="row.data ? row.data.logo : ''">
                              <img v-else class="rounded-full h-4 inline-block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/6/68/Solid_black.png">
                              <span class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out">
                                {{row.name}}
                              </span>
                              <span class="text-gray-600">
                                Lends {{formatCash(row.min_loan_amount)}} to {{formatCash(row.max_loan_amount)}}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="hover:bg-gray-100 hover:shadow-sm cursor-pointer hidden sm:w-full sm:table-row"
                        >
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                          >
                            <div>
                              <PaperToggle
                                  @onselectedd="(val) => {row.saved = val}"
                                  :selectedd="row.saved"
                                  class=""
                                >
                              </PaperToggle>
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                            @click="setRowOpen(index, row, $event)"
                          >
                            <div>
                              <img v-if="row.data && row.data.logo" class="rounded-full w-4 inline-block mr-1" :src="row.data ? row.data.logo : ''">
                              <img v-else class="rounded-full h-4 inline-block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/6/68/Solid_black.png">
                              <span class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out">
                                {{row.name}}
                              </span>
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                            @click="setRowOpen(index, row, $event)"
                          >
                            <div>
                              {{formatRank(row.paper_rank)}}
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                            @click="setRowOpen(index, row, $event)"
                          >
                            <div class="">{{countryCodeEmoji(row.data)}}</div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                            @click="setRowOpen(index, row, $event)"
                          >
                            <div>
                              {{formatCash(row.min_loan_amount)}} - {{formatCash(row.max_loan_amount)}}
                            </div>
                          </td>
                          <td
                            class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
                            @click="setRowOpen(index, row, $event)"
                          >
                            <div>
                              {{formatCash(row.min_annual_revenue)}}
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="hover:bg-gray-100 hover:shadow-sm flex sm:w-full sm:table-row"
                          @click="row.open=true"
                          v-if="row.open"
                          data-onclick="open"
                        >
                          <td colspan="6" data-onclick="open" class="">
                            <div data-onclick="open" class="px-4 py-5 sm:px-6">
                              <div class="grid-cols-2">
                                <div class="text-3xl font-black">
                                  {{row.name}}
                                </div>
                                <div>

                                </div>
                              </div>
                              <dl data-onclick="open" class="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div data-onclick="open" class="sm:col-span-2">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    About
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{row.data.description}}
                                    
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Location 
                                    <!-- {{countryCodeEmoji(row.data)}} -->
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{getGeoSummary(row)}}
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">
                                    Funding
                                    <div data-onclick="open" class="tooltip bg-gray-200 h-5 w-5 inline-block rounded-full text-center">?
                                      <span class='tooltip-text bg-gray-900 text-white normal-case p-3 -mt-8 -ml-28 rounded'>
                                        How much this company has raised (debt + equity)
                                      </span>
                                    </div>
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.crunchbase && row.data.crunchbase.handle">
                                      <a data-onclick="open" class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="`https://www.crunchbase.com/${row.data.crunchbase.handle}`">
                                        {{formatCash(row.data.metrics.raised)}}
                                      </a>
                                    </span>
                                    <span data-onclick="open" v-else>
                                      {{formatCash(row.data.metrics.raised)}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt class="text-sm font-medium text-gray-500">
                                    Email
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.site.emailAddresses && row.data.site.emailAddresses.length > 0">
                                      <a class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="row.data.site.emailAddresses">{{row.data.site.emailAddresses[0]}}</a>
                                      
                                    </span>
                                    <span data-onclick="open" v-else>No email</span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Employees
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span data-onclick="open" v-if="row.data.linkedin && row.data.linkedin.handle">
                                      <a data-onclick="open" class="font-medium text-blue-500 hover:text-blue-900 transition duration-150 ease-in-out" target="_blank" :href="`https://www.linkedin.com/${row.data.linkedin.handle}`">
                                        {{row.data.metrics.employees ? row.data.metrics.employees : '&lt; 5'}}
                                      </a>
                                    </span>
                                    <span data-onclick="open" v-else>{{row.data.metrics.employees ? row.data.metrics.employees : '&lt; 5'}}</span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Type
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    {{row.loan_type}}
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Payment
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span v-if="row.loan_type === 'Factoring'">
                                      No repayment
                                    </span>
                                    <span v-else>
                                      {{row.payment_details}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Warrants
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span>
                                      {{row.warrants ? row.warrants : 'Unknown'}}
                                    </span>
                                  </dd>
                                </div>
                                <div data-onclick="open" class="sm:col-span-1">
                                  <dt data-onclick="open" class="text-sm font-medium text-gray-500">
                                    Days to Close
                                  </dt>
                                  <dd data-onclick="open" class="mt-1 text-sm text-gray-900">
                                    <span>
                                      {{row.days_to_close ? row.days_to_close : 'Unknown'}}
                                    </span>
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            <div data-onclick="open" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 pb-8 px-6">
                              <div data-onclick="open" class="sm:col-span-2">
                                <div class="pt-3">
                                  <div class="inline pr-8">
                                    <a class="text-gray-500 hover:text-gray-900 inline" :href="`https://www.linkedin.com/${row.data.linkedin.handle}`" target="_blank">
                                      <svg class="h-5 w-5 text-gray-500 hover:text-gray-900 inline" aria-hidden="true" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <title>LinkedIn icon</title>
                                        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                                      </svg>
                                    </a>
                                  </div>
                                  <div class="inline text-gray-500 hover:text-gray-900">
                                    <a class="inline" :href="`https://twitter.com/${row.data.twitter.handle}`" target="_blank">
                                      <svg class="h-5 w-5 hover:text-gray-900 inline" aria-hidden="true" fill="currentColor" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <title>Twitter icon</title>
                                        <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                                      </svg>
                                      ({{Math.round(row.data.twitter.followers/1000)}}k)
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div data-onclick="open" class="sm:col-span-1">
                                <button 
                                  :id="row.public_id"
                                  data-onclick="open"
                                  @click="moreInfo(row)" 
                                  class="w-full py-2 px-4 border text-sm font-medium rounded-md bg-white text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                >
                                  More info
                                </button>
                              </div>
                              <div v-if="row.saved" data-onclick="open" class="sm:col-span-1">
                                <button 
                                  data-onclick="open"
                                  @click="removeFunder(row)" 
                                  class="w-full py-2 px-4 border text-sm font-medium rounded-md bg-white text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus:outline-none transition duration-150 ease-in-out"
                                >
                                  Remove
                                </button>
                              </div>
                              <div v-else data-onclick="open" class="sm:col-span-1">
                                <button 
                                  data-onclick="open"
                                  @click="saveFunder(row)" 
                                  class="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            
                            <!-- {{row.data}} -->
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div @click="login" v-if="!storeState.isLoggedIn && storeState.checkedLogin" class="cursor-pointer text-center font-bold text-lg bg-black hover:bg-gray-700 text-white block mt-4 px-4 py-4 text-base rounded">
                    Sign up to view 100+ capital providers 👉 
                  </div>
                  <div @click="applyNow" v-if="storeState.userData.savedFunders.length > 0 && storeState.isLoggedIn" class="cursor-pointer text-center font-bold text-lg bg-black hover:bg-gray-700 text-white block mt-4 px-4 py-4 text-base rounded">
                    {{storeState.userData.savedFunders.length}} providers saved, appy now 👉 
                  </div>
                </div>
              </div>
              <div class="md:col-span-1"></div>
            </div>
          </div>
        </main>
      </div>
    </div>

    <!-- END HERO -->
    <!-- CALCULATOR -->

    <!-- END CALCULATOR -->
    <!-- FAQ -->
    <div class="bg-indigo-800">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-indigo-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What is this?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                <span class="font-bold">Paper</span> is a capital marketplace for SaaS companies. 
                It's like LendingTree for SaaS funding.
                You can apply to dozens of capital providers in one click. 
                Easily compare offers and select the best option for your company.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How much can I borrow?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                This varies based on your metrics (e.g. churn, growth rate, etc.), but many funders will allow you to
                borrow up to 5x your MRR. One major benefit of <span class="font-bold">Paper</span> is you that you 
                can see the offers of several lenders, side by side, all at once.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                Why do these lenders offer more than traditional banks?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Our marketplace is made up of funders that are custom built for the needs of growing SaaS companies. 
                These partners understand SaaS and are able to better manage risk than traditional banks 
                or other lenders.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                Do you take any equity?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Some funding options include "warrants" or equity. 
                Warrants are a security that gives the holder the right to purchase company stock at a 
                specified price within a specific period of time.
                You can filter the marketplace for "non-dilutive" funders to only include 
                options that don't require equity.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How long does this all take?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Paper takes out the pain points of applying for a loan and automates as much of 
                the process as possible. Simply connect your accounts, answer a few questions 
                and we'll fill in dozens of forms for you.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What's the interest rate / APR?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Some lenders don't publish rates, but most listed heere charge  
                15 to 25% APR (Annual Percentage Rate). 
                Your rate is based on several factors including your metrics (e.g. churn, growth rate) 
                and business model.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What can I use the funds for?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Use the funds for what you think will best grow your business. 
                We see many founders using the funds to hire a bit ahead of growth 
                and invest in ad spend (especially where you've already proven a 
                <a 
                  href="https://www.profitwell.com/recur/all/calculate-and-reduce-payback-period"
                  target="_blank"
                  class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  payback period &leq; 6 months).
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <!-- END FAQ -->
    <!-- ABOUT US -->
    <div id="aboutus" class="bg-white">
      <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div class="space-y-5 sm:space-y-4">
            <h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">About Us</h2>
            <p class="text-xl leading-7 text-gray-500">
              <span class="font-extrabold">Paper</span> was born out of our own frustration 
              with how SaaS companies are funded. There are endless pitch meetings, 
              vague passes (e.g. "it's too early") and an overall lack of transparency. 
              If you you're building a 
              <a target="_blank" href="https://medium.com/strong-words/real-businesses-b21f44c99b6a">
                real business </a> 
              and want to grow faster, 
              <span class="font-extrabold">Paper</span> was built for you.
              <!-- and lack of
              transparency in the funding environment 
              for SaaS companies that are growing, but maybe not fast enough for VC. 
              We were exhausted from endless pitch meetings and didn't want to lose equity. -->
              <!-- <span class="font-extrabold">Paper</span> is made for real businesses that want to grow faster. -->
            </p>
          </div>
          <div class="lg:col-span-2">
            <ul class="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
              <li class="sm:py-8">
                <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <!-- Image -->
                  <div class="hidden pb-2/3 relative sm:block sm:pt-2/3">
                    <img class="absolute inset-0 object-cover h-auto w-full shadow-lg rounded-lg" src="/mike.png" alt="">
                  </div>
                  <div class="sm:col-span-2">
                    <div class="space-y-4">
                      <div class="text-lg leading-6 font-medium space-y-1">
                        <h4>Mike Ritchie</h4>
                        <p class="text-indigo-600">Founder &amp; CEO</p>
                      </div>
                      <div class="text-lg leading-7">
                        <p class="text-gray-500">
                          Hey there 👋! I've been running risk, analytics, and ops at banks and fintechs for over 15 years. Most recently
                          I was founder and CEO @ 
                          <a target="_blank" href="https://seekwell.io/" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">
                            SeekWell
                          </a> (a SaaS company acquired in 2021) and before that 
                          I was the Chief Risk Officer @ Blispay (a fintech acquired in 2019) 
                        </p>
                      </div>
                      <ul class="flex space-x-5">
                        <li>
                          <a target="_blank" href="https://twitter.com/mike_seekwell" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">Twitter</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://www.linkedin.com/in/michaeljritchie/" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">LinkedIn</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="mailto:mike@trypaper.io" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">Email</span>
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <!-- More people...-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- END ABOUT US -->
    <!-- FOOTER -->
    <footer class="bg-white">
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center">
          <div class="px-5 py-2">
            <a href="#aboutus" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              About
            </a>
          </div>
          <!-- <div class="px-5 py-2">
            <a href="#" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Blog
            </a>
          </div> -->
          <div class="px-5 py-2">
            <a href="mailto:contact@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Jobs
            </a>
          </div>
          <div class="px-5 py-2">
            <a href="mailto:investors@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Investors
            </a>
          </div>
          <div class="px-5 py-2">
            <a href="mailto:contact@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Partners
            </a>
          </div>
          <div class="px-5 py-2">
            <a @click="goToFunders" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Comparison
            </a>
          </div>
        </nav>
        <!-- <div class="mt-8 flex justify-center space-x-6">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">GitHub</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Dribbble</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />
            </svg>
          </a>
        </div> -->
        <p class="mt-8 text-center text-base leading-6 text-gray-400">
          &copy; 2020 Paper Financal, Inc. All rights reserved.
        </p>
      </div>
    </footer>

    <!-- END FOOTER -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { reactive, ref } from 'vue'
import Typed from 'typed.js';
import axios from 'axios';
import { store } from '../store.js';
import PaperSelect from '@/components/PaperSelect.vue'
import PaperToggle from '@/components/PaperToggle.vue'

export default {
  name: 'Landing',
  props: {
      msg: String
  },
  components: {
    // PaperMenu,
    PaperSelect,
    PaperToggle,
  },
  created() {
    this.emitter.on('user-data', () => this.onUserData());
    this.revenue = this.revenueOptions[0]
    this.product = this.productOptions[0]
    this.rating = this.ratingOptions[0]
  },
  mounted() {
    this.getFunders()
    // this.startTyped()
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        selectAll: false,
        revenueOptions: [
          {name: 'All', value: -1},
          {name: '< $100k', value: 100000},
          {name: '$100k - $500k', value: 500000},
          {name: '$500k - $1M', value: 1000000},
          {name: '$1 - $5M', value: 1000000},
          {name: '$5 - $10M', value: 5000000},
          {name: '$10M+', value: 10000000},
        ],
        revenue: {name: 'All', value: -1},
        productOptions: [
          {name: 'All', value: 'all'},
          {name: 'Credit Card', value: 'Credit Card'},
          {name: 'Factoring', value: 'Factoring'},
          {name: 'Revenue Based Financing', value: 'Revenue Based Financing'},
          {name: 'Loan', value: 'Term Loan'},
        ],
        product: {name: 'All', value: 'all'},
        ratingOptions: [
          {name: 'All', value: 'all'},
          {name: 'A', value: 'A'},
          {name: 'B', value: 'B'},
          {name: 'C+', value: 'C'},
        ],
        rating: {name: 'All', value: 'all'},
        locationOptions: [
          {name: 'All', value: 'all'},
          {name: 'United States', value: 'us'},
          {name: 'United Kingdom', value: 'uk'},
          {name: 'Germany', value: 'germany'},
        ],
        location: {name: 'All', value: 'all'},
        searchFocused: true,
        openSideBar: false,
        searchTerm: '',
        section: 'funders',
        showUserMenu: false,
        gotFunders: false,
        companyStage: 'scale',
        funderColumns: ['name', 'paper_rank', 'max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        funderCashColumns: ['max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        // detailCols: ['']
        columnLabels: {
          name: 'Funder',
          min_loan_amount: 'Revenue'
        },
        funderData: reactive([]),
        filteredFunders: [],
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  },
  methods: {
    onUserData() {
      this.updateSavedOnFunders()
      this.filterFunders()
    },
    passingSelected(value) {
      console.log("value", value)
    },
    selectAllNow() {
      for (let index = 0; index < this.filteredFunders.length; index++) {
        this.filteredFunders[index].saved = this.selectAll
      }
      this.$forceUpdate()
    },
    onSearchFocus() {
      this.searchFocused = true
      this.$nextTick(() => {
        this.$refs.actualSearch.focus()
      })
    },
    onSearchBlur() {
      this.searchFocused = false
      this.$nextTick(() => {
        this.startTyped()
      })
    },
    clearFilters() {
      this.searchTerm = ''
    },
    startTyped() {
      let hasEl = document.getElementById('typed')
      if (hasEl) {
        var typed = new Typed('#typed', {
          strings: [' ', 'Capital', 'Credit Cards', 'Factoring', 'Loans' , 'Financing'],
          typeSpeed: 50, //less is more
          backSpeed: 50,
          backDelay: 1000,
          startDelay: 50,
          bindInputFocusEvents: true,
          loop: true,
          cursorChar: '|'
        });
        typed
      }
    },
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    getGeoSummary(row) {
      if (row.data.geo.city && row.data.geo.countryCode === 'US') return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.city && row.data.geo.state) return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.state) return `${row.data.geo.state}`
      return row.data.geo
      // return row.data.geo.country
      
    },
    countryCodeEmoji(cc) {
      if (!cc) {
        cc = 'US'
      } else if (!cc.geo) {
        cc = 'US'
      } else if (!cc.geo.countryCode) {
        cc = 'US'
      } else {
        cc = cc.geo.countryCode
      }
      const CC_REGEX = /^[a-z]{2}$/i;
      const OFFSET = 127397;
      if (!CC_REGEX.test(cc)) {
        const type = typeof cc;
        throw new TypeError(
          `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
            type === 'string' ? cc : type
          }' instead.`,
        );
      }
      const chars = [...cc.toUpperCase()].map(c => c.charCodeAt() + OFFSET);
      return String.fromCodePoint(...chars);
    },
    formatRank(rank) {
      if (rank < 60) {
        return 'F'
      } else if (rank < 70) {
        return 'D'
      } else if (rank < 80) {
        return 'C'
      } else if (rank < 90) {
        return 'B'
      } else if (rank < 90) {
        return 'A'
      }
      return 'A'
    },
    setRowOpen(clickIndex, row, event) {
      if (event.target.dataset.onclick) {
        return
      }
      let newStatus = !row.open
      for (let index = 0; index < this.filteredFunders.length; index++) {
        this.filteredFunders[index].open = false
      }
      this.filteredFunders[clickIndex].open = newStatus
    },
    formatCash(cash) {
      if (cash >= 1000000) return `$${(cash/1000000).toFixed(0)}M`
      return `$${(cash/1000).toFixed(0)}K`
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paperapi.onrender.com/${endpoint}`
    },
    getAppUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://localhost:8080/${endpoint}`
      return `https://trypaper.io/${endpoint}`
    },
    updateSavedFunders() {
      this.storeState.userData.savedFunders = []
      for (let index = 0; index < this.filteredFunders.length; index++) {
        const row = this.filteredFunders[index];
        if (row.saved) this.storeState.userData.savedFunders.push(row.public_id)
      }
      this.updateUserData()
    },
    updateSavedOnFunders() {
      for (let index = 0; index < this.funderData.length; index++) {
        const f = this.funderData[index]
        if (this.storeState.userData.savedFunders.includes(f.public_id)) f.saved = true
      }
    },
    saveFunder(row) {
      row.saved = true
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      }
    },
    updateUserData() {
      if (!this.storeState.isLoggedIn || !this.storeState.gotUserData) return
      const path = this.getApiUrl('update_user_data')
      let d = {user: this.storeState.user, userData: this.storeState.userData}
      axios.post(path, d)
        .then((res) => {
          console.log('got update_user_data: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    removeFunder(row) {
      row.saved = false
      // this.storeState.userData.savedFunders.pop(row.domain)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        // save funder to users db
        this.updateUserData()
      }
    },
    applyNow() {
      this.$router.push({ name: 'Apply'})
    },
    applyToFunder(row) {
      this.saveFunder(row)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        let url = `http://${row.domain}/?ref=trypaperio`
        window.open(url, '_blank');
      }
    },
    moreInfo(row) {
      // this.saveFunder(row)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        let url = `http://${row.domain}/?ref=trypaperio`
        window.open(url, '_blank');
      }
    },
    filterFunders() {
      this.filteredFunders = this.funderData
      if (!this.storeState.isLoggedIn) {
        this.filteredFunders = this.funderData.slice(0, 10)
      }
      if (this.revenue.value > 0) {
        this.filteredFunders = this.filteredFunders.filter(row => row.min_annual_revenue <= this.revenue.value)
      }
      if (this.product.value != 'all') {
        this.filteredFunders = this.filteredFunders.filter(row => row.loan_type === this.product.value)
      }
      if (this.rating.value != 'all') {
        let ls = []
        for (let index = 0; index < this.filteredFunders.length; index++) {
          const row = this.filteredFunders[index]
          let rating = this.formatRank(row.paper_rank)
          if (rating <= this.rating.value) ls.push(row)
        }
        this.filteredFunders = ls
      }
      if (this.searchTerm.length > 0) {
        this.filteredFunders = this.filteredFunders.filter(row => row.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      }
      
    },
    getFunders() {
      this.gotFunders = false
      const path = this.getApiUrl('get_funders')
      let d = {}
      axios.post(path, d)
        .then((res) => {
          console.log('got get_funders: ', res.data)
          this.gotFunders = true
          // this.funderColumns = Object.keys(res.data.columns).filter(c => !['domain', 'data'].includes(c))
          for (let index = 0; index < res.data.data.length; index++) {
            const element = res.data.data[index];
            element.saved = false
          }
          this.funderData = reactive(res.data.data)
          if (this.storeState.isLoggedIn) {
            this.filteredFunders = this.funderData
          } else {
            this.filteredFunders = this.funderData.slice(0, 10)
            // this.filteredFunders = sample(this.funderData, 5);
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    goToFunders() {
      this.$router.push({ name: 'Login', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
    calcLoan() {
      let month = 0
      this.loan.months = []
      this.loan.years = []
      this.loan.totalPayback = 0
      this.loan.totalInterest = 0
      while (this.loan.term > month) {
        var payback = 0
        var payout = 0
        var balance = 0
        // payout = this.loan.amount / this.loan.payoutPeriod.months
        if (month <= this.loan.payoutPeriod.months - 1) {
          payout = this.loan.amount / this.loan.payoutPeriod.months
        }
        if (month == 0) {
          balance = payout
        } else {
          let prevMonth = this.loan.months[month-1]
          balance = payout + prevMonth.interest + prevMonth.balance
        }
        let interest = balance * (this.loan.rate / 12)
        
        if (month > 23) { //start paying back
          let mo23Bal = this.loan.months[22].balance
          let prevMonth = this.loan.months[month-1]
          payback = (mo23Bal / 12) + prevMonth.interest + prevMonth.payout
        } else if (month > 11) { //start paying interest
          payback = this.loan.months[month-1].interest
        }
        balance = balance - payback
        this.loan.totalPayback+=payback
        this.loan.totalInterest+=interest
        let m = {
          month: month,
          payback: payback,
          payout: payout,
          balance: balance,
          interest: interest
        }
        this.loan.months.push(m)
        if (Object.keys(this.yearLookup).includes(String(month))) {
          m.label = this.yearLookup[month]
          this.loan.years.push(m)
        }
        month+=1
      }
    },
    formatMoney(m) {
      return (m / 1000).toFixed(1)
    },
  },
  watch: {
    'revenue': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'product': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'rating': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'searchTerm': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
    'filteredFunders': {
        handler: function () {
          this.updateSavedFunders()
        },
        deep: true
    },
  },
}
/* eslint-disable no-unused-vars */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .typed-cursor {
    font-weight: 300 !important;
  }
</style>
