<template>
  <div class="">
    <!-- HERO -->
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-screen-xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <a href="#" aria-label="Home">
                    <img class="h-8 w-auto sm:h-10" src="/paper-logo.png" alt="Logo">
                  </a>
                  <div class="-mr-2 flex items-center md:hidden">
                    <button @click="showMobileMenu=true" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                      <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="hidden md:block md:ml-10 md:pr-4">
                <a href="#product" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Product</a>
                <a href="#aboutus" class="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Company</a>
                <!-- <a href="#" class="ml-8 font-medium text-indigo-600 hover:text-indigo-900 transition duration-150 ease-in-out">Log in</a> -->
              </div>
            </nav>
          </div>

          <!--
            Mobile menu, show/hide based on menu open state.

            Entering: "duration-150 ease-out"
              From: "opacity-0 scale-95"
              To: "opacity-100 scale-100"
            Leaving: "duration-100 ease-in"
              From: "opacity-100 scale-100"
              To: "opacity-0 scale-95"
          -->
          <div v-if="showMobileMenu" class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-md">
              <div class="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                <div class="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img class="h-8 w-auto" src="/paper-logo.png" alt="Logo">
                  </div>
                  <div class="-mr-2">
                    <button @click="showMobileMenu=false" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                      <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="px-2 pt-2 pb-3">
                  <a href="#product" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Product</a>
                  <a href="#aboutus" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Company</a>
                </div>
                <!-- <div>
                  <a href="#" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out" role="menuitem">
                    Log in
                  </a>
                </div> -->
              </div>
            </div>
          </div>
          <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div class="sm:text-center lg:text-left">
              <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Get capital and 
                <br class="xl:hidden">
                <span class="text-indigo-600"> keep your equity.</span>
              </h2>
              <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                <span class="font-extrabold">Paper</span> lends up to 15x your MRR in non-dilutive capital. No payments in the first year. Funds available in 3 days.
              </p>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div class="rounded-md shadow">
                  <a @click="getFunded" class="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Get funded
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a target="_blank" href="https://drift.me/mike385/meeting" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Book a call
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="">
      </div>
    </div>
    <!-- END HERO -->
    <!-- CALCULATOR -->
    <div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div id="product" class="relative">
          <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Capital to fuel growth
          </h3>
          <p class="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            <span class="font-extrabold">Paper</span> provides "Just in Time" financing. 
            Capital is disbursed when you need it (e.g. payroll) so you pay less interest 
            and get a higher multiple of your revenue.
          </p>
        </div>

        <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
          <div class="relative">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Up to 15x your MRR
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              <span class="font-extrabold">Paper</span> is custom built for growing SaaS companies, so 
              we're able to lend a much higher multiple of your revenue than banks and traditional lenders.
            </p>

            <ul class="mt-10">
              <li>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: globe-alt -->
                      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">Hire now, pay later</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      Make the key engineering, marketing, and sales hires you need to grow. 
                      Run ads and test other acquisition channels. 
                      No payments in year one.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: x-circle -->
                      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">No personal guarantor</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      Let your metrics speak for themself. 
                      <span class="font-extrabold">Paper</span> is designed to accelerate your growth minus the dilution and guarantors.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: scale -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">No hidden fees</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      No prepayment penalties or application fees.
                      You also pay less interest compared to traditional lenders because funds are distributed only when you need them.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-10 relative lg:mt-0">
            <div class="relative">
              <div class="text-3xl font-extrabold text-gray-900">
                Get
                <currency-input 
                  v-model="loan.amount" 
                  :precision=0
                  :currency="{prefix: '$ ',suffix: ''}"
                  local="en-US"
                  class="text-indigo-600 bg-gray-50 font-extrabold border-b-2 w-48 duration-150 ease-in-out focus:border-blue-300 focus:outline-none"
                />
                for
                <div class="relative inline-block">
                  <span class="inline-block w-48 rounded-md space-y-1">
                    <button 
                      @click="updatingPayoutPeriod=!updatingPayoutPeriod"
                      type="button" 
                      aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" 
                      class="bg-gray-50 text-indigo-600 font-extrabold text-3xl cursor-pointer duration-150 ease-in-out border-b-2 focus:border-blue-300 focus:outline-none pl-2 pr-2 relative text-left transition w-full"
                    >
                      <span class="block truncate">
                        {{loan.payoutPeriod.label}}
                      </span>
                      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                          <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </button>
                  </span>
                  <div class="absolute mt-1 w-40 rounded-md bg-white shadow-lg z-10">
                    <ul 
                      v-if="updatingPayoutPeriod"
                      tabindex="-1" 
                      role="listbox" 
                      aria-labelledby="listbox-label" 
                      aria-activedescendant="listbox-item-3" 
                      class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                    >
                      <li 
                        v-for="(po, index2) in payoutPeriods"
                        :key="index2"
                        @click="setPayoutPeriod(po)"
                        role="option" 
                        class="cursor-pointer text-left text-gray-900 hover:bg-gray-200 select-none relative py-2 pl-3 pr-9"
                      >
                        <span class="font-normal block truncate">
                          {{po.label}}
                        </span>
                        <span 
                          v-if="loan.payoutPeriod.months === po.months"
                          class="text-gray-600 absolute inset-y-0 right-0 flex items-center pr-4"
                        >
                          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-0 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3 rounded-l pt-8" x-max="1">
              <div class="overflow-hidden">
                <div class="p-2">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                    </div>
                    <div class="text-lg font-medium ml-5 w-0 flex-1">
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-hidden">
                <div class="p-2">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                    </div>
                    <div class="text-center text-gray-900 w-0 flex-1">
                      Payout
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-hidden">
                <div class="p-2">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                    </div>
                    <div class="text-center text-gray-900 w-0 flex-1">
                      Payback
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(year, index) in loan.years" :key="index">
              <div class="mb-14 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3 shadow-lg rounded-l" x-max="1">
                <!-- Card -->
                  <div class="bg-gray-300 overflow-hidden">
                    <div class="p-8">
                      <div class="flex items-center">
                        <div class="flex-shrink-0">
                        </div>
                        <div class="ml-5 w-0 flex-1 text-gray-900">
                          {{year.label}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white overflow-hidden">
                    <div class="p-8">
                      <div class="flex items-center">
                        <div class="flex-shrink-0">
                        </div>
                        <div class="text-center text-lg font-medium  w-0 flex-1 text-gray-900">
                          ${{formatMoney(year.payout)}}k / month
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white overflow-hidden">
                    <div class="p-8">
                      <div class="flex items-center">
                        <div class="flex-shrink-0">
                        </div>
                        <div class="text-center text-lg font-medium  w-0 flex-1 text-gray-900">
                          ${{formatMoney(year.payback)}}k / month
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="pt-0">
              <div class="rounded-md shadow">
                <a @click="getFunded" class="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Get funded
                </a>
              </div>
            </div>
            <div class="pt-8 text-base text-gray-500 text-left">
              You need at least ${{getRequiredMrr()}}k in MRR to borrow ${{(loan.amount / 1000).toFixed(0)}}k. 
              You pay a total of ${{(loan.totalInterest / 1000).toFixed(0)}}k in interest at an 
              Annual Percentage Rate (APR) of {{loan.rate*100}}%.
            </div>
            <div v-if="false">
              <div v-for="(month, index) in loan.months" :key="index">
                month: {{month.month}}
                balance: ${{formatMoney(month.balance)}}k
                payout: ${{formatMoney(month.payout)}}k
                payback: ${{formatMoney(month.payback)}}k
                interest: ${{formatMoney(month.interest)}}k
              </div>
            </div>
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            
          </div>
        </div>

        <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div class="relative mt-12 sm:mt-16 lg:mt-24">
          <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-16 lg:items-center">
            <div class="lg:col-start-2">
              <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                Not a unicorn? No problem.
              </h4>
              <p class="mt-3 text-lg leading-7 text-gray-500">
                <span class="font-extrabold">Paper</span> is made for founders building a real business 
                making real money. Do you want to grow at all costs and ignore profit? 
                <span class="font-extrabold">Paper</span> isn't for you.
              </p>

              <ul class="mt-10">
                <li>
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <!-- Heroicon name: cursor-click -->
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <h5 class="text-lg leading-6 font-medium text-gray-900">Get funding fast</h5>
                      <p class="mt-2 text-base leading-6 text-gray-500">
                        Decisions are instant when you connect your Stripe account. 
                        Your first disbursement is available within three days of approval.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="mt-10">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <!-- Heroicon name: cash -->
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <h5 class="text-lg leading-6 font-medium text-gray-900">Without dilution</h5>
                      <p class="mt-2 text-base leading-6 text-gray-500">
                        Not only is <span class="font-extrabold">Paper</span> faster than VC, we also stay out of your business and keeps things simple:
                        <ul class="list-disc">
                          <li>No pitching</li>
                          <li>No dilution</li>
                          <li>No board seat</li>
                          <li>No warrants</li>
                          <li>Simple terms (fits in a few Tweets)</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                <defs>
                  <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <div>
                <div v-for="(f, index) in feed" :key="index">
                  <div class="mb-6 grid gap-0 grid-cols-12 shadow-lg rounded-l" x-max="1">
                    <!-- Card -->
                      <div class="bg-white overflow-hidden col-span-6">
                        <div class="py-8">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                            </div>
                            <div class="text-gray-500 hover:text-gray-900 ml-5 w-0 flex-1">
                              <svg 
                                class="w-6 h-6 inline-block" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24" 
                                xmlns="http://www.w3.org/2000/svg"
                                v-html="f.icon"
                              >
                              </svg>
                              {{f.label}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bg-white overflow-hidden col-span-2">
                        <div class="py-8">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                            </div>
                            <div class="text-center font-medium text-gray-900 w-0 flex-1">
                              {{f.amount}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bg-white overflow-hidden col-span-4">
                        <div class="py-8">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                            </div>
                            <div class="text-center w-0 flex-1">
                              <span 
                                v-if="f.status == 'success'"
                                class="inline-flex items-center px-2 py-1 rounded-full text-base font-medium leading-4 bg-green-100 text-green-800 capitalize">
                                {{f.status}}
                              </span>
                              <span 
                                v-else
                                class="inline-flex items-center px-2 py-1 rounded-full text-base font-medium leading-4 bg-yellow-100 text-yellow-800 capitalize">
                                {{f.status}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END CALCULATOR -->
    <!-- FAQ -->
    <div class="bg-indigo-800">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-indigo-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How much can I borrow?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                This varies based on your metrics (e.g. churn, growth rate, etc.). For businesses with strong metrics we lend
                up to 15x your current MRR.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                Why can you lend more than banks / other lenders?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                We're custom built for the needs of a growing SaaS company. We only disburse funds as you need them (e.g. payroll),
                so we're able to better manage risk than traditional banks or other lenders.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                Do you take any equity? How about a board seat?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Nope!
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How long does this all take?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                We can make an instant decision with your Stripe account, so filling in the application takes less than 2 minutes
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What's the interest rate / APR?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                We charge between 15 to 20% APR (Annual Percentage Rate). 
                Your rate is based on your based on your metrics (e.g. churn, growth rate).
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What can I use the funds for?
              </dt>
              <dd class="text-base leading-6 text-indigo-200">
                Use the funds for what you think will best grow your business. Considering the funds are disbursed monthly, both payroll 
                and ad spend are good uses.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <!-- END FAQ -->
    <!-- ABOUT US -->
    <div id="aboutus" class="bg-white">
      <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div class="space-y-5 sm:space-y-4">
            <h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">About Us</h2>
            <p class="text-xl leading-7 text-gray-500">
              <span class="font-extrabold">Paper</span> was born out of our own frustration with the funding environment 
              for SaaS companies that are growing, but maybe not fast enough for VC. 
              We were exhausted from endless pitch meetings and didn't want to lose equity.
              <!-- <span class="font-extrabold">Paper</span> is made for real businesses that want to grow faster. -->
            </p>
          </div>
          <div class="lg:col-span-2">
            <ul class="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
              <li class="sm:py-8">
                <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <!-- Image -->
                  <div class="relative pb-2/3 sm:pt-2/3">
                    <img class="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src="/mike.png" alt="">
                  </div>
                  <div class="sm:col-span-2">
                    <div class="space-y-4">
                      <div class="text-lg leading-6 font-medium space-y-1">
                        <h4>Mike Ritchie</h4>
                        <p class="text-indigo-600">Co-Founder / CEO</p>
                      </div>
                      <div class="text-lg leading-7">
                        <p class="text-gray-500">
                          Hey there 👋! I've been running risk and operations at banks and fintechs for over 15 years. Most recently
                          I was the Chief Risk Officer @ Blispay (acquired 2019) and founded 
                          <a href="https://seekwell.io/" class="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">
                            SeekWell
                          </a> (a SaaS in the anlaytics space).
                        </p>
                      </div>
                      <ul class="flex space-x-5">
                        <li>
                          <a href="https://twitter.com/mike_seekwell" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">Twitter</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/michaeljritchie/" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">LinkedIn</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="mailto:mike@trypaper.io" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span class="sr-only">Email</span>
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <!-- More people...-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- END ABOUT US -->
    <!-- FOOTER -->
    <footer class="bg-white">
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center">
          <div class="px-5 py-2">
            <a href="#aboutus" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              About
            </a>
          </div>
          <!-- <div class="px-5 py-2">
            <a href="#" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Blog
            </a>
          </div> -->
          <div class="px-5 py-2">
            <a href="mailto:contact@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Jobs
            </a>
          </div>
          <div class="px-5 py-2">
            <a href="mailto:investors@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Investors
            </a>
          </div>
          <div class="px-5 py-2">
            <a href="mailto:contact@trypaper.io" target="_blank" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Partners
            </a>
          </div>
          <div class="px-5 py-2">
            <a @click="goToFunders" class="text-base leading-6 text-gray-500 hover:text-gray-900">
              Comparison
            </a>
          </div>
        </nav>
        <!-- <div class="mt-8 flex justify-center space-x-6">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">GitHub</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Dribbble</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />
            </svg>
          </a>
        </div> -->
        <p class="mt-8 text-center text-base leading-6 text-gray-400">
          &copy; 2020 Paper Financal, Inc. All rights reserved.
        </p>
      </div>
    </footer>

    <!-- END FOOTER -->
  </div>
</template>

<script>
// import Vue from 'vue'
// import VueCurrencyInput from 'vue-currency-input'

// Vue.use(VueCurrencyInput)

export default {
  name: 'Landing',
  props: {
      msg: String
  },
  mounted() {
    this.calcLoan()
  },
  data() {
      return {
          loan: {
            mrr: 50000,
            amount: 250000,
            rate: .15,
            multiple: 15,
            payoutPeriod: {label: '12 months', months: 12},
            term: 36,
            paybackMonths: 36,
            years: [
              {label: 'Year 1'},
              {label: 'Year 2'},
              {label: 'Year 3'},
              {label: 'Total'},
            ],
            months: [],
          },
          feed: [
            {
              label: 'Disbursement from Paper', 
              icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>', 
              amount: '$20.4k USD', 
              type: 'paper', 
              status: 'success',
              dt: 'Dec 1, 2020',
            },
            {
              label: 'Payroll - Backend Engineer', 
              icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>', 
              amount: '$10.8k USD', 
              type: 'payroll', 
              status: 'success',
              dt: 'Dec 1, 2020',
            },
            {
              label: 'New MRR', 
              icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>', 
              amount: '$2.1k USD', 
              type: 'mrr', 
              status: 'success',
              dt: 'Dec 12, 2020',
            },
            {
              label: 'Twitter Ads', 
              icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"></path>', 
              amount: '$2.1k USD', 
              type: 'ads', 
              status: 'pending',
              dt: 'Dec 14, 2020',
            },
            {
              label: 'New MRR', 
              icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>', 
              amount: '$2.1k USD', 
              type: 'mrr', 
              status: 'pending',
              dt: 'Dec 22, 2020',
            },
          ],
          updatingPayoutPeriod: false,
          year2ratio: .5,
          payoutPeriods: [
            {label: '12 months', months: 12},
            {label: '18 months', months: 18},
            {label: '24 months', months: 24}
          ],
          yearLookup: {
            0: 'Year 1',
            12: 'Year 2',
            24: 'Year 3',
          },
          showMobileMenu: false,
        }
  },
  methods: {
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    goToFunders() {
      this.$router.push({ name: 'Funders', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
    calcLoan() {
      let month = 0
      this.loan.months = []
      this.loan.years = []
      this.loan.totalPayback = 0
      this.loan.totalInterest = 0
      while (this.loan.term > month) {
        var payback = 0
        var payout = 0
        var balance = 0
        // payout = this.loan.amount / this.loan.payoutPeriod.months
        if (month <= this.loan.payoutPeriod.months - 1) {
          payout = this.loan.amount / this.loan.payoutPeriod.months
        }
        if (month == 0) {
          balance = payout
        } else {
          let prevMonth = this.loan.months[month-1]
          balance = payout + prevMonth.interest + prevMonth.balance
        }
        let interest = balance * (this.loan.rate / 12)
        
        if (month > 23) { //start paying back
          let mo23Bal = this.loan.months[22].balance
          let prevMonth = this.loan.months[month-1]
          payback = (mo23Bal / 12) + prevMonth.interest + prevMonth.payout
        } else if (month > 11) { //start paying interest
          payback = this.loan.months[month-1].interest
        }
        balance = balance - payback
        this.loan.totalPayback+=payback
        this.loan.totalInterest+=interest
        let m = {
          month: month,
          payback: payback,
          payout: payout,
          balance: balance,
          interest: interest
        }
        this.loan.months.push(m)
        if (Object.keys(this.yearLookup).includes(String(month))) {
          m.label = this.yearLookup[month]
          this.loan.years.push(m)
        }
        month+=1
      }
    },
    formatMoney(m) {
      return (m / 1000).toFixed(1)
    },
  },
  watch: {
    'loan.amount': {
        handler: function () {
          this.calcLoan()
        },
        deep: false
    },
    'loan.payoutPeriod': {
        handler: function () {
          this.calcLoan()
        },
        deep: true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
