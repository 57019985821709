<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';
import { store } from './store.js';
import { Magic } from 'magic-sdk';

const magic = new Magic('pk_live_7F06D4CDC50CBA64'); 



export default {
  name: 'App',
  props: {
      msg: String
  },
  mounted() {
    this.checkIfLoggedIn()
  },
  data() {
      return {
        store: store,
        storeState: store.state,
      }
  },
  methods: {
    async handleLogout() {
      await magic.user.logout();
      this.storeState.isLoggedIn = false
      this.storeState.user = {}
      console.log('logged out...')
    },
    async handleLogin() {
      // storeState.isLoggedIn = true
      // storeState.user = {}
      // console.log('logged out...')
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paperapi.onrender.com/${endpoint}`
    },
    getUserData() {
      const path = this.getApiUrl('login')
      let d = this.storeState.user
      axios.post(path, d)
        .then((res) => {
          console.log('got getUserData: ', res.data)
          if (res.data.userData) {
            this.storeState.userData = res.data.userData
          }
          this.storeState.gotUserData = true
          this.emitter.emit('user-data');
        })
        .catch((error) => {
          console.error(error)
          this.storeState.gotUserData = true
        })
    },
    async checkIfLoggedIn() {
      console.log('checkIfLoggedIn1...', this.storeState.isLoggedIn)
      setTimeout(() => this.storeState.gotUserData = true, 2000);
      setTimeout(() => this.storeState.checkedLogin = true, 2000);
      this.storeState.isLoggedIn = await magic.user.isLoggedIn();
      this.storeState.checkedLogin = true
      if (this.storeState.isLoggedIn) {
        this.storeState.user = await magic.user.getMetadata();
        this.getUserData()
      } else {
        this.storeState.gotUserData = true
      }
      console.log('checkIfLoggedIn2...', this.storeState.isLoggedIn)
      this.emitter.emit('user-login');
    },
  }
}
</script>

<style>
  @import './assets/styles/styles.css';
</style>
