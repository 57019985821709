// import Vue from 'vue'
// import VueRouter from 'vue-router'
import {createRouter, createWebHistory} from 'vue-router'
// import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'
import Apply from '../views/Apply.vue'
import Funders from '../views/Funders.vue'
import Metrics from '../views/Metrics.vue'
import Analyze from '../views/Analyze.vue'
import Loan from '../views/Loan.vue'
import Login from '../views/Login.vue'
import Callback from '../views/Callback.vue'
import Logout from '../views/Logout.vue'

// Vue.use(VueRouter)

const routerHistory = createWebHistory()

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics
  },
  {
    path: '/analyze',
    name: 'Analyze',
    component: Analyze
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply
  },
  {
    path: '/loan',
    name: 'Loan',
    component: Loan
  },
  {
    path: '/funders',
    name: 'Funders',
    component: Funders
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new createRouter({
  mode: 'history',
  history: routerHistory,
  base: process.env.BASE_URL,
  routes: routes
})

export default router
